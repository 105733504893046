<template>
    <div class="employee-card" :class="{ 'employee-card_blocked': employee.is_blocked }">
        <div class="employee-card__content">
            <div class="employee-card__info">
                <p class="employee-card__subtitle">{{ employee.role_name }} {{ employee.job_title ?
        `(${employee.job_title})` : '' }}</p>
                <h3 class="employee-card__name">{{ employee.full_name }}</h3>
                <div class="employee-card__status" v-if="!employee.is_blocked">Активно</div>
                <div class="employee-card__status employee-card__status_blocked" v-else>Заблокировано</div>
            </div>
        </div>
        <router-link class="employee-card__edit" v-if="role === 'MAIN_HR'" :to="`/employees/edit/${employee.id}`">
            <img src="@/assets/images/icons/pencil-black.svg" alt="edit">
        </router-link>

        <router-link class="employee-card__edit" v-if="role === 'SUPER_ADMIN'"
            :to="`/companies/${companyId}/edit/${employee.id}`">
            <img src="@/assets/images/icons/pencil-black.svg" alt="edit">
        </router-link>
    </div>
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();

const role = store.getters["auth/role"];

defineProps({
    employee: {
        type: Object,
        required: true
    },
    companyId: Number
})
</script>

<style scoped lang="scss">
.employee-card {
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 #0000000A;
    transition: .3s;

    &_blocked {
        box-shadow: none;
        cursor: no-drop;
    }

    &__edit {
        width: 28px;
        height: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-gray-1;
        cursor: pointer;
        opacity: 0;
        transition: .3s;

        img {
            width: 20px;
        }

        &:active {
            background-color: $light-gray-2;
        }
    }

    &:hover {
        box-shadow: 2px 2px 24px 0px #1E39591F;
    }

    &_blocked:hover {
        box-shadow: none !important;
    }

    &:hover &__edit {
        opacity: 1;
    }

    &__content {
        display: flex;
    }

    &_blocked &__content {
        opacity: 0.3;
    }

    &__ava {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__info {
        margin-left: 12px;
    }

    &__name {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 600;
    }

    &__subtitle {
        color: $gray;
        font-size: 12px;
        font-weight: 400;
    }

    &__email {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
    }

    &__status {
        margin-top: 12px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0;
        color: $dark-blue;
    }
}
</style>
