<template>
    <div class="access-closed">
        <div class="access-closed__block">
            <div class="access-closed__block-title">
                <img src="@/assets/images/icons/stop.svg" alt="stop">
                Нет доступа к админ панели
            </div>
            <p class="access-closed__description">Срок действия договора вашей компании истек, пожалуйста обратитесь к
                администратору</p>
        </div>
    </div>
</template>

<script setup>
import {onMounted} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();

onMounted(() => {
    document.querySelector('body').style.overflow = 'hidden'
    router.push('/')
})
</script>

<style scoped lang="scss">
.access-closed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.32);
    user-select: none;
    cursor: no-drop;

    &__block {
        position: absolute;
        bottom: 0;
        right: 32px;
        width: calc(100% - 346px);
        padding: 32px 0;
        border-radius: 56px 56px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        text-align: center;
    }

    &__block-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;

        img {
            margin-right: 8px;
        }
    }

    &__description {
        max-width: 400px;
        margin-top: 8px;
        font-size: 16px;
    }
}
</style>
