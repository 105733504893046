import api from "./api";

const companies = {
    getEmployees(query) {
        return api.get(`/employees`, { params: query});
    },
    getEmployee(id, companyId) {
        return api.get(`/employees/${id}?company_id=${companyId}`);
    },
    postEmployee(query, body) {
        return api.post(`/employees`, body, { params: query});
    },
    putEmployee(employeeId, query, body) {
        return api.put(`/employees/${employeeId}`, body, { params: query});
    },
    deleteEmployee(employeeId, query) {
        return api.delete(`/employees/${employeeId}`, { params: query});
    },
    deleteAndTransfer(employeeId, changeVacanciesEmployeeId, companyId) {
        return api.post(`/employees/${employeeId}/changeVacanciesEmployee/${changeVacanciesEmployeeId}?company_id=${companyId}`);
    },
    deleteAndClose(employeeId, companyId, body) {
        return api.post(`/employees/${employeeId}/closeVacancies?company_id=${companyId}`, body);
    }
};

export default companies;
