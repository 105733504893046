<template>
    <div class="employees">
        <MainHeader
            v-if="listEmployees.employees"
            class="super-admins__header"
            title="Сотрудники"
            :number="listEmployees.employees.length ? listEmployees.employees_count : ''"
            btnText="Новый сотрудник"
            @btnClick="$router.push('/employees/new')"
        />
        <div class="employees__segmented-control">
            <VSegmentedControl
                :items="filteringOptions"
                :modelValue="activeFilter"
                @update:modelValue="filtration"
            />
        </div>

        <div class="employees__content">
            <div class="employees__cards">
                <EmployeeCard
                    class="employees__card"
                    v-for="employee in listEmployees.employees"
                    :key="employee.id"
                    :employee="employee"/>
            </div>
        </div>

        <div class="employees__no-content" v-if="listEmployees.employees && !listEmployees.employees.length">
            <VNoContent
                title="Список сотрудников пуст"
                description="По выбранному фильтру ничего не найдено"
            />
        </div>
    </div>
    <AccessClosed v-if="listEmployees.blocked_by_contract"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import employee from "@/api/employee";
import MainHeader from "@/components/MainHeader";
import EmployeeCard from "@/components/EmployeeCard";
import AccessClosed from "@/components/AccessClosed"

const filteringOptions = [
    {
        id: "all",
        label: "Все",
    },
    {
        id: "0",
        label: "Активные",
    },
    {
        id: "1",
        label: "Заблокированные",
    },
];

const activeFilter = ref("all");

const listEmployees = ref([])
const getEmployees = async (params) => {
    try {
        listEmployees.value = await employee.getEmployees(params)
    } catch (error) {
        console.log(error)
    }
}

onMounted(() => getEmployees())

const filtration = (val) => {
    if (val === activeFilter.value) return;
    activeFilter.value = val;

    if (val === "all") {
        getEmployees();
    } else {
        getEmployees({is_blocked: val});
    }
};
</script>

<style scoped lang="scss">
.employees {
    padding-top: 32px;

    &__segmented-control {
        margin-top: 24px;
        display: flex;
    }

    &__no-content {
        width: 100%;
        height: calc(100vh - 155px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        margin-top: 32px;
    }

    &__cards {
        margin-top: -24px;
        margin-left: -32px;
        display: flex;
        flex-wrap: wrap;
    }

    &__card {
        width: calc(33.333% - 32px);
        margin-top: 24px;
        margin-left: 32px;
    }
}
</style>
